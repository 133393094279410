import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import SignUpModal from '../components/SignUpModal';
import {
  BodyTextBig,
  CTASection,
  FlexWrap,
  GlobalHero,
  GraySection,
  HeroHeading,
  ImageWrap,
  SectionTitle,
  TextWrap,
  WhiteSection,
} from '../components/Global';
import MediaBreakDown from '../styles/MediaBreakDown';
import playImg from '../components/TrialHeader/play.png';
import HowItWorksWebex from '../components/HowItWorksWebex';


const Hero = styled(GlobalHero)`
  ${MediaBreakDown.lg`
    .hero-img {
      max-width: 620px;
    }
  `}
`;


const WebexPage = ({ location }) => {
  const [isOpen, setOpen] = useState(false);

  // HANDLERS:
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Layout location={location}>
      <SEO
        title="Workast for Webex | Work & Task Management App - Try it Today!"
        description="Workast is the only work management application you need in Webex. Capture tasks, manage projects, and track work with Workast in Webex."
        imageName="webex-hero.png"
      />
      <Container>
        <Hero>
          <div className="hero-text">
            <HeroHeading>
              Task Management for Webex
            </HeroHeading>
            <BodyTextBig mt="30px" mb="45px">
              With Workast you can set up projects, create tasks,
              capture to-dos, set due dates and assign work right from within Webex.
            </BodyTextBig>
            <div className="button-wrap">
              <SignUpModal buttonText="Add to Webex" />
              <div className="label">No credit card required.</div>
            </div>
          </div>
          <div className="hero-img">
            <div className="cta-wrapper">
              <SignUpModal buttonText="Add to Webex" />
              <div className="label">No credit card required</div>
            </div>
            {typeof ModalVideo !== 'object' && (
              <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="BxnwwUy71eg"
                onClose={handleClose}
              />
            )}
            <div
              role="presentation"
              className="img-video-wrapper"
              onClick={handleClick}
            >
              <Image name="webex-hero.png" alt="hero screenshot" />
              <img className="play-img" src={playImg} alt="play" />
            </div>
          </div>
        </Hero>
      </Container>
      <GraySection>
        <SectionTitle>
          How it works
        </SectionTitle>
        <HowItWorksWebex />
      </GraySection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="top" direction="column-reverse">
            <ImageWrap width="55%">
              <Image name="webex-list-view.png" alt="form" />
            </ImageWrap>
            <TextWrap width="40%">
              <SectionTitle mb="30px">
                Create tasks directly in Webex
              </SectionTitle>
              <BodyTextBig>
                Workast is the only app you need to manage teamwork in Webex.
                Easily create tasks in a Webex space,
                assign the task and set a due date all at once.
              </BodyTextBig>
              <BodyTextBig>
                Switch to Messages in Webex to continue the conversation.
              </BodyTextBig>
              <BodyTextBig>
                Team members know exactly what work needs to be done and
                is reminded of their tasks within Webex.
              </BodyTextBig>
            </TextWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <GraySection>
        <SectionTitle>
          Visualize projects in Webex
        </SectionTitle>
        <div className="check-list">
          <BodyTextBig>
            Manage your projects and tasks in a beautiful board view.
            Manage task cards on the board, move tasks from one list
            to another, complete a task and assign it to a team member.
          </BodyTextBig>
          <Image name="webex-board-view.png" alt="Board view" />
        </div>
      </GraySection>
      <CTASection pMaxWidth="530px">
        <Container>
          <SectionTitle>
            Get started today
          </SectionTitle>
          <BodyTextBig>
            Workast is the task management app for Webex teams.
            Get started in less than 30 seconds. No credit card required.
          </BodyTextBig>
          <SignUpModal buttonText="Add to Webex" />
        </Container>
      </CTASection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="top">
            <TextWrap width="30%">
              <SectionTitle mb="30px">
                Manage project deadlines
              </SectionTitle>
              <BodyTextBig>
                Use the Workast calendar to manage project deadlines for your
                team and ensure tasks are always completed.
              </BodyTextBig>
              <BodyTextBig>
                See all the tasks due for the day, week or month with the Workast
                calendar view in a Webex space.
              </BodyTextBig>
              <BodyTextBig>
                Plan tasks for your team, manage workload,
                change deadlines and more with the flexibility of the Workast calendar view.
              </BodyTextBig>
            </TextWrap>
            <ImageWrap width="55%">
              <Image name="webex-calendar-view.png" alt="Calendar view" />
            </ImageWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <GraySection>
        <SectionTitle>
          Easy to use timeline
        </SectionTitle>
        <div className="check-list">
          <BodyTextBig>
            Task management made easy with the flexibility of a timeline to chronologically
            view all the work that your team needs done from within a Webex space.
          </BodyTextBig>
          <Image name="webex-timeline-view.png" alt="Timeline view" />
        </div>
      </GraySection>
      <CTASection>
        <Container>
          <SectionTitle>
            Get teamwork sorted
          </SectionTitle>
          <BodyTextBig>
            Start managing your teamwork today with Workast.
            Install Workast and get up and running in less than a minute.
          </BodyTextBig>
          <SignUpModal buttonText="Add to Webex" />
        </Container>
      </CTASection>
      <WhiteSection textAlign="left">
        <Container>
          <FlexWrap alignItems="top" direction="column-reverse">
            <ImageWrap width="55%">
              <Image name="webex-completed.png" alt="Completed view" />
            </ImageWrap>
            <TextWrap width="40%">
              <SectionTitle mb="30px">
                Completed tasks
              </SectionTitle>
              <BodyTextBig>
                Complete tasks directly from a Webex space with the click of a button.
                View all the tasks your team has completed simply within the Webex space.
              </BodyTextBig>
              <BodyTextBig>
                Get greater visibility of teamwork and productivity levels with Workast in Webex.
              </BodyTextBig>
            </TextWrap>
          </FlexWrap>
        </Container>
      </WhiteSection>
      <GraySection textAlign="left">
        <Container>
          <FlexWrap alignItems="top">
            <TextWrap width="35%">
              <SectionTitle mb="30px">
                Reminders and Notifications
              </SectionTitle>
              <BodyTextBig>
                Never worry about missed deadlines with automatic reminders
                for team members when they are assigned a task.
                Task management made easy with Workast in Webex.
              </BodyTextBig>
              <BodyTextBig>
                Notifications are sent directly from within Webex.
              </BodyTextBig>
            </TextWrap>
            <ImageWrap width="55%">
              <Image name="webex-notifications.png" alt="Notifications" />
            </ImageWrap>
          </FlexWrap>
        </Container>
      </GraySection>
      <CTASection>
        <Container>
          <SectionTitle>
            Get started now
          </SectionTitle>
          <BodyTextBig>
            Install Workast to your Webex space and start organizing your team work today.
          </BodyTextBig>
          <SignUpModal buttonText="Add to Webex" />
        </Container>
      </CTASection>
    </Layout>
  );
};

WebexPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default WebexPage;
