import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import theme from '../../styles/theme';
import Image from '../Image';
import MediaBreakDown from '../../styles/MediaBreakDown';

const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0;
  background: #F7F9FF;
  ${MediaBreakDown.lg`
    margin: 30px auto 0;
  `}
`;
const Card = styled.div`
  position: relative;
  z-index: 1;
  margin: 15px 20px;
  width: calc(100% - 40px) !important;
  height: 500px;
  border-radius: 6px;
  outline: none;
  .text-wrap {
    padding: 27px 23px 37px;
    background: ${theme.background.light};
    border: 1px solid ${theme.borderColor.silver};
    border-top: 0px;
  }
  h5 {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 400;
    color: ${theme.textColor.darkGrayText};
  }
  p {
    font-size: 18px;
    line-height: 21.6px;
  }
  ${MediaBreakDown.lg`
    margin: 15px 10px;
    width: calc(100% - 20px) !important;
  `}
  @media(max-width: 370px) {
    height: 550px;
  }
`;
const ImageWrap = styled.div`
  background: ${props => props.bgColor};
  height: 220px;
  padding: 23px;
  text-align: center;
  .gatsby-image-wrapper {
    height: 174px;
  }
`;
const HowItWorksWebex = () => {
  const settings = {
    infinite: true,
    autoPlay: true,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '20%',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
        }
      }
    ]
  };
  return (
    <SliderWrap>
      <Slider {...settings}>
        <Card>
          <ImageWrap bgColor={theme.background.yellow}>
            <Image
              name="webex-add-app-to-webex.png"
              alt="add app to webex"
            />
          </ImageWrap>
          <div className="text-wrap">
            <h5>
              1. In a Webex Space click
              <strong> +Apps</strong>
            </h5>
            <p>
              Login to Webex desktop application and choose a Space to create
              tasks in. Click on
              <strong> +Apps</strong>
              .
            </p>
          </div>
        </Card>
        <Card>
          <ImageWrap bgColor={theme.background.blue}>
            <Image
              name="webex-choose-workast.png"
              alt="choose workast"
            />
          </ImageWrap>
          <div className="text-wrap">
            <h5>
              2. Install
              <strong> Tasks by Workast</strong>
            </h5>
            <p>
              From the Apps list, find
              <strong> Tasks by Workast </strong>
              and select the app to install Workast
              to the Webex space.
            </p>
          </div>
        </Card>
        <Card>
          <ImageWrap bgColor={theme.background.red}>
            <Image
              name="webex-enter-email.png"
              alt="enter email"
            />
          </ImageWrap>
          <div className="text-wrap">
            <h5>
              3. Use
              <strong> Webex account email </strong>
              to login
            </h5>
            <p>
              Use your Webex account email to create an account with Workast.
              No extra passwords set up required.
            </p>
          </div>
        </Card>
      </Slider>
    </SliderWrap>
  );
};

export default HowItWorksWebex;
